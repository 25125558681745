import BasePage from '@/found/components/table_page';

export default {
  name: 'Branch_activity_query',
  extends: BasePage,
  data() {
    return {

    };
  },
  components: {

  },
  async created() {
    await this.getConfigList('Branch_activity_query');
  },
  methods: {},
};
